@import "font.css";

/* COLOURS */
:root {
  --primary-color: rgb(0, 142, 0);
  --dark-primary-color: rgb(126, 126, 126);
  --secondary-color: rgb(254, 150, 42);
  --dark-secondary-color: rgb(228, 157, 26);
  --light-background-color: #f6f6f6;
  --dark-background-color: #ededea;
  --primary-text-color: #2d2d2d;
  --transparent-light: #00000000;
  --transparent-light-10: #0000000a;
  --transparent-dark: #ffffff00;
  --transparent-dark-10: #ffffff0a;
  --white: #ffffff;
}

html,
body,
#root {
  font-family: "Poppins", sans-serif;
  height: 100%;
  font-feature-settings: "kern";
}

/* Utility */

.hover-pointer:hover {
  cursor: pointer;
}

/* Bootstrap overrides */

.btn {
  border-radius: 3px !important;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-primary:hover {
  background-color: var(--dark-primary-color) !important;
  border-color: var(--dark-primary-color) !important;
}

.btn-primary:active {
  background-color: var(--dark-secondary-color) !important;
  border-color: var(--dark-secondary-color) !important;
}

.btn-outline-primary {
  background-color: var(--light-background-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--secondary-color) !important;
}

.btn-outline-primary:hover {
  background-color: var(--dark-background-color) !important;
  border-color: var(--dark-primary-color) !important;
  color: var(--secondary-color) !important;
}

.btn-outline-primary:active {
  background-color: var(--dark-secondary-color) !important;
  border-color: var(--dark-secondary-color) !important;
}

.btn-outline-light {
  background-color: white !important;
  border-color: var(--dark-primary-color) !important;
  color: black;
}

.btn-secondary {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.btn-secondary:hover {
  background-color: var(--dark-secondary-color) !important;
  border-color: var(--dark-secondary-color) !important;
}

.btn-secondary:active {
  background-color: var(--dark-background-color) !important;
  border-color: var(--dark-background-color) !important;
}

.btn-outline-secondary {
  background-color: var(--light-background-color) !important;
  border-color: var(--secondary-color) !important;
  color: var(--secondary-color) !important;
}

.btn-outline-secondary:hover {
  background-color: var(--dark-background-color) !important;
  border-color: var(--dark-secondary-color) !important;
  color: var(--secondary-color) !important;
}

.btn-outline-secondary:active {
  background-color: var(--light-background-color) !important;
  border-color: var(--light-background-color) !important;
}

.btn-transparent-light {
  background-color: var(--transparent-light) !important;
  border: none !important;
  color: var(--primary-text-color) !important;
}

.btn-transparent-light:hover {
  background-color: var(--transparent-light-10) !important;
}

.btn-transparent-dark {
  background-color: var(--transparent-dark) !important;

  border: none !important;
}

.btn-transparent-dark:hover {
  background-color: var(--transparent-dark-10) !important;
}

h1,
h2 {
  font-weight: bold;
}

input,
.input-group,
.input-group-text {
  border-radius: 3px !important;
}

input[type="checkbox"] {
  border-radius: 10px !important;
}

.header-container {
  height: 150px;
  background-color: var(--primary-color);
}

#login-page-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#login-image-panel {
  position: relative;
  left: 0;
  top: 0;
  z-index: -1;
  filter: brightness(0.8);
}

#login-input-panel {
  height: 100%;
  width: 100%;
  background-color: var(--light-background-color);
  position: absolute;
  left: 0;
  top: 0;
}

.full-page-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20;
}

.full-height-container {
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: inherit;
}

input[type="checkbox"] {
  border-color: var(--primary-color) !important;
}

input[type="checkbox"]:checked {
  background-color: var(--primary-color) !important;
}

.large-spinner {
  height: 75px;
  width: 75px;
  color: var(--primary-color);
}

.big-icon {
  height: 160px;
  width: 160px;
  color: var(--primary-color);
}

/* Custom scroll */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa;
}

.pageTitle {
  color: var(--primary-color);
  font-size: 36px;
  font-weight: "900";
  font-feature-settings: "kern";
  font-family: "Poppins Black";
}

.title {
  color: var(--primary-color);
  font-size: 24px;
  font-feature-settings: "kern";
  font-family: "PoppinsBold";
}

.subTitle {
  color: var(--primary-color);
  font-size: 18px;
  font-feature-settings: "kern";
  font-family: "PoppinsBold";
}

.description {
  color: black;
  font-size: 13px;
  font-feature-settings: "kern";
  font-family: "Poppins";
}

body{
  overflow-x: hidden;
}